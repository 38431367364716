import React, { useState, useRef } from 'react';
import heartImage from './assets/images/heart.gif';
import celebrationSong from './assets/audio/celebration.mp3';
import confetti from 'canvas-confetti';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { motion } from 'framer-motion';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once




import img1 from './assets/images/img1.jpeg';
import img2 from './assets/images/img2.jpeg';
import img3 from './assets/images/img3.jpeg';
import img4 from './assets/images/img4.jpeg';

const images = [img1, img2, img3, img4];


const translations = [
  { lang: "Chinese (Simplified)", text: "你愿意嫁给我吗，Akansha?", src: '/audio/translations/translation_zh_cn.mp3' },
  { lang: "Spanish", text: "¿Quieres casarte conmigo, Akansha?", src: '/audio/translations/translation_es.mp3' },
  { lang: "English", text: "Will you marry me, Akansha?", src: '/audio/translations/translation_en.mp3' },
  { lang: "Hindi", text: "क्या तुम मुझसे शादी करोगी, आकांक्षा?", src: '/audio/translations/translation_hi.mp3' },
  { lang: "Arabic", text: "هل تقبلين الزواج بي، أكانشا؟", src: '/audio/translations/translation_ar.mp3' },
  { lang: "Bengali", text: "তুমি কি আমার সাথে বিয়ে করবে, আকাংশা?", src: '/audio/translations/translation_bn.mp3' },
  { lang: "Portuguese", text: "Você quer casar comigo, Akansha?", src: '/audio/translations/translation_pt.mp3' },
  { lang: "Russian", text: "Ты выйдешь за меня замуж, Аканша?", src: '/audio/translations/translation_ru.mp3' },
  { lang: "Japanese", text: "アカンシャ、結婚してくれる？", src: '/audio/translations/translation_ja.mp3' },
  { lang: "German", text: "Willst du mich heiraten, Akansha?", src: '/audio/translations/translation_de.mp3' }
];



function Proposal() {
  const [showTranslations, setShowTranslations] = useState(false);
  const celebrationAudioRef = useRef(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);


 

  // Function to trigger fireworks
  const triggerFireworks = () => {
    var end = Date.now() + (15 * 1000); // run for 15 seconds

    // Fireworks function
    const interval = setInterval(() => {
      if (Date.now() > end) {
        return clearInterval(interval);
      }

      const particleCount = 100; // adjust as needed
      confetti({
        particleCount,
        startVelocity: 30,
        spread: 360,
        origin: {
          x: Math.random(), // random location across the screen
          y: Math.random() // random location vertically
        }
      });
    }, 200); // fire every 200 milliseconds
  };






  const handleYes = () => {
    setShowTranslations(false); // Stop translations if they are playing
    celebrationAudioRef.current.play(); // Play the celebration song
    triggerFireworks(); // Trigger fireworks
  };

  return (
    <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#fff0f5' }}>
      <h1 style={{ color: '#800000',marginBottom: '0.5em'  }}>Will You Marry Me, Akansha?</h1>
      <img src={heartImage} alt="Heart Image" style={{ width: '300px', margin: '0 auto' }} />

   

{/* Poem section */}
      <div className="poem" style={{ marginTop: '10px' }}>
        <p>अभी उठा हूँ नींद से तुम्हारा ख्वाब देख के,</p>
        <p>पूरी नींद खुली नहीं अभी पलकें उठी नहीं।</p>
        <p>ये शाम अभी तो आएगी, तुम्हारी याद लाएगी।</p>
        <p>रात का डर सा है, काली घटा सर पर है,</p>
        <p>तुम हो कि हो नहीं, ये बात आज की नहीं।</p>
        <p>शजर हजार सा था अभी, मुझे ज़रा झटक तो दो,</p>
        <p>और पास आओ ज़रा, नैन ज़रा मटक तो दो।</p>
        <p>आज आई हो तुम यहाँ, गुलाबी घटा छाई है,</p>
        <p>रूह में अब मेरे थोड़ी जान आई है।</p>
        <p>सीप सी आँखें तेरी, मंद मंद मुस्कान है,</p>
        <p>काली जुल्फें तो आम हैं, होली के रंग में जाम हैं।</p>
        <p>निगाहें कुछ झुकी सी हैं, ज़रा देखो मेरी ओर भी,</p>
        <p>कुछ खुली सी हो अभी, कुछ हया भी है अभी।</p>
        <p>कलाई आगे बढ़ाओ ज़रा थोड़ी पास आओ तो,</p>
        <p>चेहरा नूरानी अभी भी है, एक नगीना लगाओ तो।</p>
        <p>सोच लो ज़रा ज़रा, ज्यादा वक्त है नहीं,</p>
        <p>मेरा ख्याल साफ है, मैं तुम या मैं नहीं।</p>
      </div>



      {/* Translations button */}  {/* Toggle button for showing/hiding translations */}
      <button
        className="translation-toggle-button"
        onClick={() => setShowTranslations(!showTranslations)}
      >
        {showTranslations ? 'Hide Translations' : 'Show Translations'}
      </button>

      {showTranslations && (
        <div className="translations-section">
          <h2>Translations</h2>
          {translations.map((translation, index) => (
            <div className="translation-item" key={index}>
              <strong>{translation.lang}:</strong>
              <span>{translation.text}</span>
              <audio src={translation.src} controls />
            </div>
          ))}
        </div>
      )}





      <div className="response-section">
        <h2>Response</h2>
        <div className="button-container">
          <button className="response-button" id="yes-btn" onClick={handleYes}>Yes</button>
          <button className="response-button" id="no-btn" onClick={() => alert("Maybe another time... 😔")}>No</button>
        </div>
      </div>

    

      <div className="image-gallery">
              <h2>Our Memories</h2>
              <Swiper
                spaceBetween={10}
                slidesPerView={3}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                    src={image}
                    alt={`Memory ${index}`}
                    className="gallery-image"
                    onClick={() => {
                      setPhotoIndex(index);
                      setIsOpen(true);
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
      </div>

      {/* Lightbox component */}
            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + images.length - 1) % images.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % images.length)
                }
              />
            )}




      <audio ref={celebrationAudioRef} src={celebrationSong} style={{ display: 'none' }}>
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

export default Proposal;

import React from 'react';
import './App.css';
import Proposal from './Proposal';  // Make sure this import points to where your Proposal.js file is located.

function App() {
  return (
    <div className="App">
      <Proposal />
    </div>
  );
}

export default App;
